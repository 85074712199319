import React, { useState, useEffect, useContext } from "react";
import { graphql, PageProps } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../../components/Layout";
import Container from "react-bootstrap/Container";
import SEO from "../../components/Seo";
import StructuredData from "../../components/StructuredData";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { LocalizedContextInterface, PortableText, Seo } from "../../types/SanityTypes";
import { LocalisedPageContext } from "../../types/PageTypes";
import { getLocalizedPath } from "../../utils/gatsbyBuild";
import { withPrefix } from "gatsby";
import "./styles.scss";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import Spinner from "react-bootstrap/Spinner";
import BlockContent from "@sanity/block-content-to-react";
import { RichTextSerializers } from "../../utils/richTextSerializers";

type PromotigoPageProps = {
  sanityPromotigo: {
    _id: string;
    name: string;
    promotigoID: string;
    primaryHeadText: string;
    secondaryHeadText: string;
    subHeading: string;
    warningText: string;
    seo: Seo;
    slug: {
      current: string;
    };
    backgroundImage: {
      alt: string;
      image: {
        asset: {
          url: string;
        }
      }
    },
    mainImage: {
      alt: string;
      image: {
        asset: {
          url: string;
        }
      }
    },
    _rawPrivacyCookies: PortableText;
    completionMessage?: string
    imagePosition: string;
  };
} & LocalizedContextInterface;

type PromotigoPageContext = LocalisedPageContext;

export const query = graphql`
  query sanityPromotigoAll($_id: String, $language: String, $market: String) {
    sanityPromotigo(_id: { eq: $_id }) {
      _id
      name
      promotigoID
      primaryHeadText
      secondaryHeadText
      subHeading
      warningText
      completionMessage
      slug {
        current
      }
      seo {
        metaTitle
        metaDescription
        jsonld
      }
      _rawPrivacyCookies(resolveReferences: { maxDepth: 8 })
      backgroundImage {
        alt
        image {
          asset {
            url
          }
        }
      }
      mainImage {
        alt
        image {
          asset {
            url
          }
        }
      }
      imagePosition
    }
    ...LocalizedContext
  }
`;

const FilterProducts = (props: PageProps<PromotigoPageProps, PromotigoPageContext>) => {
  const promotigoPage = props.data.sanityPromotigo;
  const { siteUrl } = useSiteMetadata();
  const localizedPath = withPrefix(getLocalizedPath(promotigoPage.slug.current, promotigoPage._id));
  // const [loaded, setLoaded] = useState(false);
  const onlyForm = promotigoPage.mainImage && promotigoPage.mainImage.image.asset ? false : true;
  const imagePosition = promotigoPage.imagePosition;
  const [screenWidth, setScreenWidth] = useState<number | null>(typeof window !== "undefined" ? window.innerWidth : null);

  const params = typeof window !== "undefined" ? new URLSearchParams(window.location.search) : null;
  const successUrl = params?.get("success");
  const completitionMessage = promotigoPage.completionMessage?.replace(/([.!?])\s*/g, "$1\n");

  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  function scrollOnSubmit() {
    const promotigoForm = document.getElementsByClassName("header")[0];

    if (!promotigoForm) {
      setTimeout(() => {
        scrollOnSubmit();
      }, 500);
    } else {
      promotigoForm?.scrollIntoView(true);
    }
  }

  const scrollTop = () => {
    if (window) {
      window.scrollTo(0, 0);
    }
  };
  useEffect(() => {
    configPromotigo();
    if (window) {
      setTimeout(scrollTop, 50);
    }
  }, []);

  const configPromotigo = () => {
    if (window.promotigo && promotigoPage.promotigoID) {
      window.promotigo.server = "https://api2.promotigo.com/";
      window.promotigo.formId = promotigoPage.promotigoID;
      window.promotigo.bootstrap = true;
      window.promotigo.language = props.pageContext?.language;
      window.promotigo.renderForm("#promotigoForm");
      window.promotigo.customColumns = "mycolumns";
      window.promotigo.columns = "col-xs-12 col-sm-12 col-md-12 col-md-offset-3 col-lg-12 col-lg-offset-4";
      window.promotigo.preSubmit = scrollOnSubmit;
    } else {
      setTimeout(configPromotigo, 1000);
    }
  };

  function handleWindowSizeChange() {
    setScreenWidth(window.innerWidth);
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.id = "form-app-js";
    script.src = "https://forms-widget.unileversolutions.com/widget.js";
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = screenWidth ? screenWidth <= 576 : false;

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={promotigoPage.seo.metaTitle}
      pageType={"Promotigo"}
      contentType={"Promotigo"}
    >
      <Helmet>
        <link rel="stylesheet" href="https://forms-widget.unileversolutions.com/widget.rtl.css" />
      </Helmet>

      <StructuredData customData={promotigoPage.seo.jsonld} />
      
      <SEO title={promotigoPage.seo.metaTitle} description={promotigoPage.seo.metaDescription} />
      <div
        className={promotigoPage.backgroundImage?.image ? "page-promotigo background-active" : "page-promotigo"}
        style={{
          backgroundImage: `url(${
            promotigoPage.backgroundImage && promotigoPage.backgroundImage.image
              ? promotigoPage.backgroundImage.image.asset.url
              : ""
          })`
        }}
      >
        <Container fluid>
          <div className={"form-container " + imagePosition}>
            {onlyForm ? null : (
              <div className={"col-12 col-md-6 " + imagePosition}>
                {isMobile && (
                  <div className={"form-box mobile"}>
                    <>
                      {promotigoPage.primaryHeadText && (
                        <h1 className={"primary-text"}>{promotigoPage.primaryHeadText}</h1>
                      )}
                      {promotigoPage.secondaryHeadText && (
                        <h2 className={"secondary-text"}>{promotigoPage.secondaryHeadText}</h2>
                      )}
                      {promotigoPage.subHeading && <p className={"sub-heading-text"}>{promotigoPage.subHeading}</p>}
                      {promotigoPage.warningText && <p className={"warning-text"}>{promotigoPage.warningText}</p>}
                    </>
                  </div>
                )}
                {imagePosition == "center" ? (
                  <>
                    <picture className="placeholder">
                      <source
                        media={"(min-width: 768px)"}
                        srcSet={
                          urlBuilder
                            .image(promotigoPage.mainImage.image.asset.url)
                            .auto("format")
                            .quality(80)
                            .width(1400)
                            .height(520)
                            .format("webp")
                            .url() as string
                        }
                      />
                      <img
                        className="img-fluid"
                        src={
                          urlBuilder
                            .image(promotigoPage.mainImage.image.asset.url)
                            .auto("format")
                            .quality(80)
                            .width(560)
                            .height(210)
                            .format("webp")
                            .url() as string
                        }
                        alt={promotigoPage.mainImage.alt}
                        loading={"lazy"}
                      />
                    </picture>
                  </>
                ) : (
                  <>
                    <picture className="placeholder">
                      <source
                        media={"(min-width: 1200px)"}
                        srcSet={
                          urlBuilder
                            .image(promotigoPage.mainImage.image.asset.url)
                            .auto("format")
                            .quality(80)
                            .width(680)
                            .height(680)
                            .format("webp")
                            .url() as string
                        }
                      />
                      <source
                        media={"(min-width: 768px)"}
                        srcSet={
                          urlBuilder
                            .image(promotigoPage.mainImage.image.asset.url)
                            .auto("format")
                            .quality(80)
                            .width(600)
                            .height(600)
                            .format("webp")
                            .url() as string
                        }
                      />
                      <img
                        className="img-fluid"
                        src={
                          urlBuilder
                            .image(promotigoPage.mainImage.image.asset.url)
                            .auto("format")
                            .quality(80)
                            .width(480)
                            .height(480)
                            .format("webp")
                            .url() as string
                        }
                        alt={promotigoPage.mainImage.alt}
                        loading={"lazy"}
                      />
                    </picture>
                  </>
                )}
              </div>
            )}
            <div className={onlyForm ? "form-box col-12 col-lg-6 offset-md-3" : "form-box col-12 col-md-6 desktop"}>
              {successUrl ? (
                <h2 className={"completionMessage"}>{completitionMessage}</h2>
              ) : (
                <>
                  {!isMobile && (
                    <>
                      {promotigoPage.primaryHeadText && (
                        <h1 className={"primary-text"}>{promotigoPage.primaryHeadText}</h1>
                      )}
                      {promotigoPage.secondaryHeadText && (
                        <h2 className={"secondary-text"}>{promotigoPage.secondaryHeadText}</h2>
                      )}
                      {promotigoPage.subHeading && <p className={"sub-heading-text"}>{promotigoPage.subHeading}</p>}
                      {promotigoPage.warningText && <p className={"warning-text"}>{promotigoPage.warningText}</p>}
                    </>
                  )}
                  <div
                    className="form-widget"
                    data-mode="preview"
                    data-form-id={promotigoPage.promotigoID}
                    data-success-page={`${localizedPath}/?success=true`}
                  >
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                  <div className={"privacy-container"}>
                    <BlockContent serializers={RichTextSerializers()} blocks={promotigoPage._rawPrivacyCookies} />
                  </div>
                </>
              )}
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default FilterProducts;
